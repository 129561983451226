import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './index.less';

import headerSvg from './images/header.svg';

import iconZoom from './icons/Zoom.svg';
import iconScrollVertical from './icons/Scroll-Vertical.svg';

import 'fullscreen-polyfill';

import { createRoot } from 'react-dom/client';
import React, { useState, useRef, useLayoutEffect, useEffect, Fragment } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.parcel2';

import { VariableSizeList as List } from 'react-window';

import { useElementSize } from 'usehooks-ts'

const issues = [
  {
    title: "2022 | 01",
    cover: "https://storage.fenykepeszkor.hu/files/covers/2022-01.jpg",
    publication: "https://storage.fenykepeszkor.hu/files/issues/2022-01.pdf"
  },
  {
    title: "2022 | 02 | Fotósviadal különszám",
    cover: "https://storage.fenykepeszkor.hu/files/covers/2022-02.jpg",
    publication: "https://storage.fenykepeszkor.hu/files/issues/2022-02.pdf"
  },
  {
    title: "2022 | 03",
    cover: "https://storage.fenykepeszkor.hu/files/covers/2022-03.jpg",
    publication: "https://storage.fenykepeszkor.hu/files/issues/2022-03.pdf"
  },
  {
    title: "2023 | 01",
    cover: "https://storage.fenykepeszkor.hu/files/covers/2023-01.jpg",
    publication: "https://storage.fenykepeszkor.hu/files/issues/2023-01b.pdf"
  },
  {
    title: "2023 | 02",
    cover: "https://storage.fenykepeszkor.hu/files/covers/2023-02.jpg",
    publication: "https://storage.fenykepeszkor.hu/files/issues/2023-02.pdf"
  },
  {
    title: "2023 | 03",
    cover: "https://storage.fenykepeszkor.hu/files/covers/2023-03.jpg",
    publication: "https://storage.fenykepeszkor.hu/files/issues/2023-03.pdf"
  },
  {
    title: "2023 | 04",
    cover: "https://storage.fenykepeszkor.hu/files/covers/2023-04.jpg",
    publication: "https://storage.fenykepeszkor.hu/files/issues/2023-04b.pdf"
  },
  {
    title: "2024 | 01",
    cover: "https://storage.fenykepeszkor.hu/files/covers/2024-01.jpg",
    publication: "https://storage.fenykepeszkor.hu/files/issues/2024-01b.pdf"
  }
];

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
};

const cookieStorageKey = 'cookies_accepted';

function App() {
  const [welcomeShown, setWelcomeShown] = useState(true);
  const [issue, setIssue] = useState();
  const [touchFriendly, setTouchFriendly] = useState(true);
  const [cookiesAccepted, setCookiesAccepted] = useState(window.localStorage.getItem(cookieStorageKey) || false);

  useEffect(
    () => {
      if (cookiesAccepted) {
        gtag('consent', 'update', {
          'ad_storage': 'granted',
          'analytics_storage': 'granted'
        });
      }
    },
    [cookiesAccepted]
  );

  function onStart(mobileDevice, issue) {
    setTouchFriendly(mobileDevice);
    setIssue(issue);
    setWelcomeShown(false);
  }

  function onCookiesAccepted() {
    window.localStorage.setItem(cookieStorageKey, true);
    setCookiesAccepted(true);
  }

  return (
    <Fragment>
      {welcomeShown ? <Welcome onStart={onStart} />  : issue && <MagazineViewer touchFriendly={touchFriendly} issue={issue} />}
      {!cookiesAccepted && <CookieConsent onAccept={onCookiesAccepted} />}
    </Fragment>
  )
}

function Issue(props) {
  const {issue, onClick}  = props;
  const [touchFriendly, setTouchFriendly] = useState(false);

  function handleOnClick() {    
    onClick(touchFriendly, issue);
  }

  function handleOnTouchEnd(event) {
    setTouchFriendly(true);
  }

  return (
    <li><a href="#" onClick={handleOnClick} onTouchEnd={handleOnTouchEnd}><img src={issue.cover}/><p>{issue.title}</p></a></li>
  )
}

function Welcome(props) {
  const onStart = props.onStart || (() => {});  

  return (<div className="welcome">
    <div className="container">    
    <img className="headerImage" src={headerSvg} />
         
    <p>
      Eddig megjelent számok:
    </p>

    <ul className="issues">
      {issues.reverse().map((issue, index) => <Issue key={index} index={index} issue={issue} onClick={onStart} />)}
    </ul>

    <p>
      <h1>IMPRESSZUM</h1>
      
<span className="emphasis">ISSN:</span> 3004-2224 (Online)<br/>
<span className="emphasis">URL:</span> <a href="https://fenyrajz.org/">https://fenyrajz.org/</a><br/>
<span className="emphasis">KIADÓ:</span> Pannon Fényképészkör Egyesület<br/>
<span className="emphasis">KIADÓ SZÉKHELYE:</span> 1077 Budapest, Wesselényi u. 13. 4/2<br/>
<span className="emphasis">FELELŐS KIADÓ:</span> Ruppert Tamás<br/>
<span className="emphasis">FŐSZERKESZTŐ:</span> Vainel Gergely Gyula<br/>
<span className="emphasis">KÉPI LEKTOR:</span> Bácsi Róbert<br/>
<span className="emphasis">TÖRDELÉS, GRAFIKA:</span> Vainel Gergely Gyula, Miklós Gabriel<br/>
<span className="emphasis">KORREKTÚRA:</span> Papp Anita<br/>
<span className="emphasis">SZERKESZTŐSÉG ELÉRHETŐSÉGEI:</span><br/> 
<a href="https://fenykepeszkor.hu" target='_blank'>https://fenykepeszkor.hu</a><br/>
<a href="mailto:fenyrajz@fenykepeszkor.hu">fenyrajz@fenykepeszkor.hu</a>
    </p>
    
    </div>
  </div>)
}

function MagazineViewer(props) {  
  const { touchFriendly, issue } = props;
  const pdfFile = new URL(issue.publication, import.meta.url).toString();

  const [scale, setScale] = useState(1.0);
  const [progress, setProgress] = useState(100);
  const [documentRef, { width, height }] = useElementSize();
  const [pageSizes, setPageSizes] = useState(null);

  function onDocumentLoadSuccess(pdf) {
    const promises = Array.from({ length: pdf.numPages }, (v, i) => i + 1).map((pageNumber) => {
      return pdf.getPage(pageNumber);
    });

    Promise.all(promises).then((pages) => {
      const sizes = Array.from({ length: pages.length });

      for (const page of pages) {
        sizes[page._pageIndex] = {
          w: page.view[2],
          h: page.view[3]
        }
      }

      setPageSizes(sizes);      
    });
  }

  function onDocumentLoadProgress({ loaded, total }) { 
    const loadProgress = (loaded / total) * 100;
    setProgress(loadProgress | 0);
  }

  function onDocumentLoadError(error) {
    alert("Sajnos hiba történt a betöltés során, kérlek próbáld újra betölteni az oldalt.");
  }

  function toggleFullScreen() {      
    if (!document.fullscreenElement) {      
        document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  return (
    <div className="magazineViewer">
      {!touchFriendly && <header>
        <div className="scaling">
          <input type="button" className="square" value="-" onClick={() => { setScale(scale - 0.1) }}></input>
          <span>
          {` ${(scale * 100) | 0}% `}
          </span>
          <input type="button" className="square" value="+"  onClick={() => { setScale(scale + 0.1) }}></input>          
        </div>

        <div className="controls">
          <div className="meta">
            <span className="title">Fényrajz magazin</span>&nbsp;<span className="date">({issue.title})</span>
          </div>                
          <input type="button" value="Teljes képernyős mód" onClick={toggleFullScreen}></input>
        </div>
      </header>}    
      {(progress < 100) && <div className="progress">
        <div className="bar" style={{width: `${progress}%`}}></div>
      </div>}      
      <div className="magazineViewer__container" ref={documentRef}>
        <Document 
          file={pdfFile} 
          loading="Betöltés..."
          error="Hiba történt a dokumentum betöltése során."
          onLoadProgress={onDocumentLoadProgress} 
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError} 
          externalLinkTarget="_blank"
          options={options}>
          <PageList {...{ width, height, scale, pageSizes, touchFriendly }} />
        </Document>
      </div>
    </div>
  );
}

const PageList = React.memo(function PageList(props) {
  const { width, height, scale, pageSizes, touchFriendly } = props;
  const listRef = useRef(null);

  useLayoutEffect(
    () => { listRef.current && listRef.current.resetAfterIndex(0), [width] }
  );  

  const pageIndexBias = touchFriendly ? 1 : 0;
  
  
  return (
    pageSizes && <List 
      ref={listRef}
      width={width}
      height={height} 
      itemCount={pageSizes.length + pageIndexBias} 
      estimatedItemSize={800}       
      itemSize={(index) => {
        if (touchFriendly && index == 0) {
          return 400;
        }          

        const pageSize = pageSizes[index - pageIndexBias];
        let itemHeight = pageSize.h;
        
        if (width < pageSize.w * scale) { // responsive ratio
          itemHeight *= ((width - 48) / pageSize.w);            
        } else {
          itemHeight *= scale;
        }
        
        return itemHeight + (16 * window.devicePixelRatio);
      }} 
      overscanCount={2} 
      className="magazineViewer__pageList">
        {({ style, index }) => (
          touchFriendly && index == 0 ? (<div className="bothAxisCenter" style={style}>
            <div className="touch-onboarding">
              <div className="icons">            
              <img src={iconScrollVertical} />
              <img src={iconZoom} />              
            </div>
            <p>
              A megszokott érintő mozdulatok (görgetés, nagyítás) itt is alkalmazhatóak.
            </p>
          </div>
        </div>) : (<div style={style} className="page-container">
              <Page key={`page_${index + 1}`} pageNumber={index + 1 - pageIndexBias} loading="Betöltés..." scale={scale} error="Hiba történt az oldal betöltése során." renderTextLayer={false} renderAnnotationLayer={true} />
          </div>)
        )}
    </List>
  );
});

function CookieConsent(props) {
  return (<div className="cookieConsent">
      <span>
      Kedves Látogató! Tájékoztatjuk, hogy a honlap felhasználói élmény fokozásának érdekében sütiket alkalmazunk. A honlapunk használatával ön a tájékoztatásunkat tudomásul veszi.
      </span>
      <button className="primary" onClick={() => props.onAccept && props.onAccept()}>Elfogadom</button> <a className="button secondary" href="https://fenykepeszkor.hu/adatvedelmi-iranyelvek/">Adatvédelmi irányelvek</a>
    </div>)
}

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App />);